import { css } from '@emotion/react';
import { type CSSProperties, type ForwardedRef, forwardRef, memo } from 'react';

import { TableRow, type TableRowProps } from '../table-row/TableRow';

import * as styles from './TableDataRow.styles';

export type TableDataRowProps = TableRowProps & {
  readonly backgroundColor?: CSSProperties['backgroundColor'];
};

const TableDataRowForwardRef = forwardRef(function TableDataRow(
  { backgroundColor, children, ...props }: TableDataRowProps,
  ref: ForwardedRef<HTMLTableRowElement>,
) {
  return (
    <TableRow
      {...props}
      ref={ref}
      css={[
        styles.tableDataRow,
        backgroundColor
          ? css`
              background-color: ${backgroundColor};
            `
          : styles.defaultTableDataRowColor,
      ]}
    >
      {children}
    </TableRow>
  );
});

export const TableDataRow = memo(TableDataRowForwardRef);
