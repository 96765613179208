import { css, type Theme } from '@emotion/react';

import { ShadowVariant } from '@amalia/design-system/meta';

import { BadgeColor, BadgeHue, BadgeSize, BadgeStatus } from './Badge.types';

export const badge = (theme: Theme) => css`
  display: inline-flex;
  align-items: center;
  flex: none;

  border-radius: ${theme.ds.borderRadiuses.round};
  white-space: nowrap;

  transition: ${theme.ds.transitions.default()};

  &.${BadgeSize.SMALL} {
    padding: 2px 8px;
    gap: 4px;

    &.${BadgeStatus.SUCCESS},
      &.${BadgeStatus.WARNING},
      &.${BadgeStatus.MISSING},
      &.${BadgeStatus.DANGER},
      &.${BadgeStatus.PENDING},
      &.${BadgeStatus.READY} {
      padding-left: 6px;
    }
  }

  &.${BadgeSize.MEDIUM} {
    padding: 2px 12px;
    gap: 6px;

    &.${BadgeStatus.SUCCESS},
      &.${BadgeStatus.WARNING},
      &.${BadgeStatus.MISSING},
      &.${BadgeStatus.DANGER},
      &.${BadgeStatus.PENDING},
      &.${BadgeStatus.READY} {
      padding-left: 8px;
    }
  }

  &.${BadgeSize.LARGE} {
    padding: 2px 16px;
    gap: 8px;

    &.${BadgeStatus.SUCCESS},
      &.${BadgeStatus.WARNING},
      &.${BadgeStatus.MISSING},
      &.${BadgeStatus.DANGER},
      &.${BadgeStatus.PENDING},
      &.${BadgeStatus.READY} {
      padding-left: 12px;
    }
  }

  &.${BadgeColor.PRIMARY} {
    background-color: ${theme.ds.colors.primary[50]};
  }

  &.${BadgeHue.BLUE} {
    background-color: ${theme.ds.hues.blue[50]};
  }

  &.${BadgeHue.BROWN} {
    background-color: ${theme.ds.hues.brown[50]};
  }

  &.${BadgeHue.CYAN} {
    background-color: ${theme.ds.hues.cyan[50]};
  }

  &.${BadgeHue.GREY} {
    background-color: ${theme.ds.colors.gray[100]};
  }

  &.${BadgeHue.MAGENTA} {
    background-color: ${theme.ds.hues.magenta[50]};
  }

  &.${BadgeHue.ORANGE} {
    background-color: ${theme.ds.hues.orange[50]};
  }

  &.${BadgeHue.PURPLE} {
    background-color: ${theme.ds.hues.purple[50]};
  }

  &.${BadgeHue.YELLOW} {
    background-color: ${theme.ds.hues.yellow[50]};
  }

  &.${BadgeHue.GREEN}, &.${BadgeStatus.SUCCESS} {
    color: ${theme.ds.colors.success[500]};
    background-color: ${theme.ds.colors.success[50]};
  }

  &.${BadgeStatus.WARNING} {
    color: ${theme.ds.hues.orange[500]};
    background-color: ${theme.ds.hues.orange[50]};
  }

  &.${BadgeStatus.MISSING} {
    color: ${theme.ds.colors.gray[500]};
    background-color: ${theme.ds.colors.gray[100]};
  }

  &.${BadgeHue.RED}, &.${BadgeStatus.DANGER} {
    color: ${theme.ds.colors.danger[500]};
    background-color: ${theme.ds.colors.danger[50]};
  }

  &.${BadgeStatus.PENDING} {
    color: ${theme.ds.hues.cyan[300]};
    background-color: ${theme.ds.hues.cyan[50]};
  }

  &.${BadgeStatus.READY} {
    color: ${theme.ds.hues.blue[500]};
    background-color: ${theme.ds.hues.blue[50]};
  }
`;

export const text = (theme: Theme) => css`
  .${BadgeColor.PRIMARY} > & {
    color: ${theme.ds.colors.primary[700]};
  }

  .${BadgeHue.BLUE} > & {
    color: ${theme.ds.hues.blue[900]};
  }

  .${BadgeHue.BROWN} > & {
    color: ${theme.ds.hues.brown[700]};
  }

  .${BadgeHue.CYAN} > & {
    color: ${theme.ds.hues.cyan[700]};
  }

  .${BadgeHue.GREY} > & {
    color: ${theme.ds.colors.gray[600]};
  }

  .${BadgeHue.MAGENTA} > & {
    color: ${theme.ds.hues.magenta[700]};
  }

  .${BadgeHue.ORANGE} > & {
    color: ${theme.ds.hues.orange[900]};
  }

  .${BadgeHue.PURPLE} > & {
    color: ${theme.ds.hues.purple[700]};
  }

  .${BadgeHue.YELLOW} > & {
    color: ${theme.ds.hues.yellow[700]};
  }

  .${BadgeHue.GREEN} > &,
  .${BadgeStatus.SUCCESS} > & {
    color: ${theme.ds.colors.success[900]};
  }

  .${BadgeStatus.WARNING} > & {
    color: ${theme.ds.hues.orange[900]};
  }

  .${BadgeStatus.MISSING} > & {
    color: ${theme.ds.colors.gray[600]};
  }

  .${BadgeHue.RED} > &,
  .${BadgeStatus.DANGER} > & {
    color: ${theme.ds.colors.danger[900]};
  }

  .${BadgeStatus.PENDING} > & {
    color: ${theme.ds.hues.cyan[700]};
  }

  .${BadgeStatus.READY} > & {
    color: ${theme.ds.hues.blue[900]};
  }
`;

export const withShadow = (theme: Theme) => css`
  box-shadow: ${theme.ds.shadows[ShadowVariant.SOFT]};
`;
