import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amalia/design-system/components';
import { type RelationshipRequest, type Relationship } from '@amalia/payout-definition/plans/types';

import { RelationshipsApiClient } from './relationships.api-client';
import { relationshipsMutationKeys, relationshipsQueryKeys } from './relationships.keys';

export const useRelationships = () =>
  useQuery({
    queryKey: relationshipsQueryKeys.list(),
    queryFn: () => RelationshipsApiClient.list(),
  });

export const useDeleteRelationship = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: relationshipsMutationKeys.delete(),
    mutationFn: (relationshipId: Relationship['id']) => RelationshipsApiClient.delete(relationshipId),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Link deleted successfully.' }));
      await Promise.all([queryClient.invalidateQueries({ queryKey: relationshipsQueryKeys.list() })]);
    },
    onError: (err) => {
      snackError(
        formatMessage(
          { defaultMessage: 'An error occurred while deleting the link: {errorMessage}.' },
          { errorMessage: err.message },
        ),
      );
    },
  });
};

export const useCreateRelationship = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: relationshipsMutationKeys.create(),
    mutationFn: RelationshipsApiClient.create,
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Link created successfully.' }));
      await Promise.all([queryClient.invalidateQueries({ queryKey: relationshipsQueryKeys.list() })]);
    },
    onError: (err) => {
      snackError(
        formatMessage(
          { defaultMessage: 'An error occurred while creating the link: {errorMessage}.' },
          { errorMessage: err.message },
        ),
      );
    },
  });
};

export const usePatchRelationship = () => {
  const queryClient = useQueryClient();
  const { snackSuccess, snackError } = useSnackbars();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationKey: relationshipsMutationKeys.patch(),
    mutationFn: ({
      relationshipId,
      relationshipToUpdate,
    }: {
      relationshipId: Relationship['id'];
      relationshipToUpdate: RelationshipRequest;
    }) => RelationshipsApiClient.update(relationshipId, relationshipToUpdate),
    onSuccess: async () => {
      snackSuccess(formatMessage({ defaultMessage: 'Link updated successfully.' }));
      await Promise.all([queryClient.invalidateQueries({ queryKey: relationshipsQueryKeys.list() })]);
    },
    onError: (err) => {
      snackError(
        formatMessage(
          { defaultMessage: 'An error occurred while updating the link: {errorMessage}.' },
          { errorMessage: err.message },
        ),
      );
    },
  });
};
