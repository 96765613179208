import {
  IconCalendarDown,
  IconCalendarUp,
  IconSortAscendingLetters,
  IconSortAscendingNumbers,
  IconSortDescendingLetters,
  IconSortDescendingNumbers,
} from '@tabler/icons-react';
import { fromPairs } from 'lodash';
import { type ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { type TablerIconElement } from '@amalia/design-system/components';

const buildOptions = (
  labelAscending: ReactNode,
  labelDescending: ReactNode,
  iconAscending: TablerIconElement,
  iconDescending: TablerIconElement,
) => [
  {
    value: 'ASC' as const,
    label: labelAscending,
    filterLabel: 'asc',
    icon: iconAscending,
  },
  {
    value: 'DESC' as const,
    label: labelDescending,
    filterLabel: 'desc',
    icon: iconDescending,
  },
];

const buildOptionsForFormat = (format: FormatsEnum) => {
  switch (format) {
    case FormatsEnum.date:
    case FormatsEnum['date-time']:
      return buildOptions(
        <FormattedMessage defaultMessage="Oldest to newest" />,
        <FormattedMessage defaultMessage="Newest to oldest" />,
        <IconCalendarUp />,
        <IconCalendarDown />,
      );
    case FormatsEnum.currency:
    case FormatsEnum.percent:
    case FormatsEnum.number:
      return buildOptions(
        <FormattedMessage defaultMessage="Lowest to highest" />,
        <FormattedMessage defaultMessage="Highest to lowest" />,
        <IconSortAscendingNumbers />,
        <IconSortDescendingNumbers />,
      );

    default:
      return buildOptions(
        <FormattedMessage defaultMessage="A to Z" />,
        <FormattedMessage defaultMessage="Z to A" />,
        <IconSortAscendingLetters />,
        <IconSortDescendingLetters />,
      );
  }
};

export const useSortOptionsByFormat = () =>
  useMemo(() => fromPairs(Object.values(FormatsEnum).map((format) => [format, buildOptionsForFormat(format)])), []);
