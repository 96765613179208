import { css } from '@emotion/react';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { buildPropertiesOptions } from '@amalia/data-capture/fields/components';
import { type CustomObjectDefinition, getProperties } from '@amalia/data-capture/record-models/types';
import {
  Button,
  FormFieldLabel,
  Group,
  IconButton,
  Select,
  type SelectOption,
  Stack,
} from '@amalia/design-system/components';
import { type FilterUniqueBy } from '@amalia/payout-definition/plans/types';

interface OrderByFormComponentProps {
  readonly customObjectDefinition?: CustomObjectDefinition;
  readonly value: FilterUniqueBy[] | null;
  readonly onChange: (value: FilterUniqueBy[]) => void;
}

export const UniqueByFormComponent = memo(function UniqueByFormComponent({
  customObjectDefinition,
  value,
  onChange,
}: OrderByFormComponentProps) {
  const propertyOptions = useMemo(
    () => buildPropertiesOptions(getProperties(customObjectDefinition), customObjectDefinition?.externalIds[0] ?? null),
    [customObjectDefinition],
  );

  const onChangeFieldMachineName = useCallback(
    (index: number, fieldMachineName: string) => {
      onChange(value!.map((v, i) => (i === index ? { ...v, fieldMachineName } : v)));
    },
    [value, onChange],
  );

  const handleAddElement = useCallback(() => {
    const propertyToAdd = customObjectDefinition && getProperties(customObjectDefinition).at(0)?.machineName;
    if (propertyToAdd) {
      onChange((value ?? []).concat({ fieldMachineName: propertyToAdd }));
    }
  }, [value, onChange, customObjectDefinition]);

  const handleDelete = useCallback(
    (index: number) => {
      onChange((value ?? []).filter((_, i) => index !== i));
    },
    [value, onChange],
  );

  return (
    <Stack gap={value?.length ? 12 : 6}>
      <FormFieldLabel>
        <FormattedMessage defaultMessage="Unique by" />
      </FormFieldLabel>
      <Stack gap={12}>
        {(value ?? []).map(({ fieldMachineName }, index) => (
          <Group
            // eslint-disable-next-line react/no-array-index-key -- no id to order on here.
            key={index}
            align="center"
            gap={12}
            css={css`
              & > div {
                flex-grow: 1;
                max-width: 600px;
              }
            `}
          >
            <Select<SelectOption<string>, false, false, false>
              isClearable={false}
              options={propertyOptions}
              size={Select.Size.SMALL}
              value={fieldMachineName}
              onChange={(newValue) => onChangeFieldMachineName(index, newValue)}
            />

            <IconButton
              icon={<IconTrash />}
              label={<FormattedMessage defaultMessage="Delete unique by" />}
              size={IconButton.Size.SMALL}
              onClick={() => handleDelete(index)}
            />
          </Group>
        ))}
      </Stack>
      <div>
        <Button
          icon={<IconPlus />}
          size={Button.Size.SMALL}
          variant={Button.Variant.PRIMARY_LIGHT}
          onClick={handleAddElement}
        >
          <FormattedMessage defaultMessage="Add unique by" />
        </Button>
      </div>
    </Stack>
  );
});
