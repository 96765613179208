import { TokenType } from '@amalia/amalia-lang/tokens/types';

import { type DesignerTokenIconProps } from './DesignerTokenIcon';
import { formatIcons, linkIcons, propertyIcons, tokenIcons } from './icon.mappers';

const SINGLE_ICON_TOKENS = [TokenType.FUNCTION, TokenType.KEYWORD, TokenType.LINK, TokenType.FILTER];

export const getTokenIcon = ({
  tokenType,
  tokenFormat,
  propertyRef,
  relationshipType,
}: Partial<DesignerTokenIconProps>) => {
  if (tokenType === TokenType.LINK && relationshipType) {
    return linkIcons[relationshipType];
  }

  if (tokenType && SINGLE_ICON_TOKENS.includes(tokenType) && tokenType in tokenIcons) {
    return tokenIcons[tokenType];
  }

  // As long as ref is available for Property, we do an exception
  if (tokenType === TokenType.PROPERTY && propertyRef && propertyRef in propertyIcons) {
    return propertyIcons[propertyRef];
  }

  if (tokenFormat && tokenFormat in formatIcons) {
    return formatIcons[tokenFormat];
  }

  if (tokenType && tokenType in tokenIcons) {
    return tokenIcons[tokenType];
  }

  return null;
};
