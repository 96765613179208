import { css } from '@emotion/react';
import { isArray } from 'lodash';
import { memo, type ReactNode } from 'react';

export type InlineInputsProps = {
  // How to position the elements on the row, in grid-template-columns syntax.
  // Examples:
  //   - repeat(3, 1fr): 3 columns of the same size
  //   - 1fr 100px 1fr: the column in the middle takes 100px
  //   - 1fr auto 1fr: the column in the middle will take the least width possible
  readonly layout?: CSSStyleDeclaration['gridTemplateColumns'];

  readonly children: ReactNode;

  readonly className?: string;
};

const SEPARATOR_CLASS_NAME = 'inline-inputs-separator';

const InlineInputsBase = function InlineInputs({ children, layout, className }: InlineInputsProps) {
  const gridTemplateColumns = layout ?? `repeat(${isArray(children) ? children.length : 1}, 1fr)`;
  return (
    <div
      className={className}
      css={(theme) => css`
        display: grid;
        box-shadow: ${theme.ds.shadows.soft};

        grid-template-columns: ${gridTemplateColumns};

        input,
        .${SEPARATOR_CLASS_NAME} {
          border-radius: 0;
          box-shadow: none;
        }

        > :not(:first-child) input,
        > :not(:first-child).${SEPARATOR_CLASS_NAME} {
          border-left: none;
        }

        > :first-child input,
        > :first-child.${SEPARATOR_CLASS_NAME} {
          border-radius: ${theme.ds.borderRadiuses.squared} 0 0 ${theme.ds.borderRadiuses.squared};
        }

        > :last-child input,
        > :last-child.${SEPARATOR_CLASS_NAME} {
          border-radius: 0 ${theme.ds.borderRadiuses.squared} ${theme.ds.borderRadiuses.squared} 0;
        }
      `}
    >
      {children}
    </div>
  );
};

export type InlineInputsSeparatorProps = {
  readonly children: ReactNode;
  readonly className?: string;
};

const Separator = function InlineInputsSeparator({ children, className }: InlineInputsSeparatorProps) {
  return (
    <div
      className={SEPARATOR_CLASS_NAME}
      css={[
        className,
        (theme) => css`
          background-color: ${theme.ds.colors.gray[0]};
          border: 1px solid ${theme.ds.colors.gray[100]};
          border-radius: ${theme.ds.borderRadiuses.squared};
          outline: none;
          outline-offset: -1px;

          padding: 0 8px;

          display: flex;
          justify-content: center;
          align-items: center;
        `,
      ]}
    >
      {children}
    </div>
  );
};

export const InlineInputs = Object.assign(memo(InlineInputsBase), {
  Separator,
});
