export const relationshipsQueryKeys = {
  all: () => ['relationships'] as const,
  list: () => [...relationshipsQueryKeys.all(), 'list'] as const,
} as const;

export const relationshipsMutationKeys = {
  all: () => ['relationships'] as const,
  create: () => [...relationshipsMutationKeys.all(), 'create'] as const,
  patch: () => [...relationshipsMutationKeys.all(), 'patch'] as const,
  delete: () => [...relationshipsMutationKeys.all(), 'delete'] as const,
} as const;
