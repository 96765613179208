import { type Theme, css } from '@emotion/react';

export const tableDataRow = css`
  height: 48px;
`;

export const defaultTableDataRowColor = (theme: Theme) => css`
  &:nth-of-type(even) {
    background-color: ${theme.ds.colors.graySecondary[50]};
  }

  &:nth-of-type(odd) {
    background-color: ${theme.ds.colors.gray[0]};
  }
`;
