import { FormatsEnum } from '@amalia/data-capture/fields/types';
import { type CurrencySymbolsEnum } from '@amalia/ext/iso-4217';

/**
 * Each amount -MIN_VALID_AMOUNT < amount < MIN_VALID_AMOUNT is considered to be 0.
 */
export const MIN_VALID_AMOUNT = 0.005;
export const formatAmount = (amount: number, currency: CurrencySymbolsEnum) => {
  const formatter = new Intl.NumberFormat(undefined, {
    style: FormatsEnum.currency,
    currency,
    minimumFractionDigits: 2,
    trailingZeroDisplay: 'stripIfInteger',
  });

  // If the amount is contained between -0.004999... and 0.0049999..., it
  // will be rounded then displayed as -0 and we want to avoid that.
  const cappedAmount = Math.abs(amount) < MIN_VALID_AMOUNT ? 0 : amount;

  return formatter.format(cappedAmount);
};
