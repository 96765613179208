import { http } from '@amalia/core/http/client';
import { type RelationshipRequest, type Relationship } from '@amalia/payout-definition/plans/types';

export class RelationshipsApiClient {
  public static async list(): Promise<Relationship[]> {
    const { data } = await http.get<Relationship[]>('/relationships');
    return data;
  }

  public static async get(relationshipId: string): Promise<Relationship> {
    const { data } = await http.get<Relationship>(`/relationships/${relationshipId}`);
    return data;
  }

  public static async create(relationship: RelationshipRequest): Promise<Relationship> {
    const { data } = await http.post<Relationship>('/relationships/', relationship);
    return data;
  }

  public static async update(
    relationshipId: Relationship['id'],
    relationshipToUpdate: RelationshipRequest,
  ): Promise<Relationship> {
    const { data } = await http.patch<Relationship>(`/relationships/${relationshipId}`, relationshipToUpdate);
    return data;
  }

  public static async delete(relationshipId: Relationship['id']): Promise<void> {
    await http.delete(`/relationships/${relationshipId}`);
  }
}
